
          @import "@/styles/global/utils.scss";
          @import "@/styles/cjp/utils.scss";
        






































































































































.spinner {
  height: 100vh;
}
